import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from "./pages/app";
import {Provider} from "react-redux";
import configureStore from "./configureStore";
import axios from "axios";
import Alert from "./components/atoms/alert";
import {BASE_URL, LOCAL_BASE_URL} from "./constants/setting";

axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? BASE_URL : LOCAL_BASE_URL;
axios.defaults.withCredentials = true;

axios.interceptors.request.use(
    request => {
        return request;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (error.response.status !== 401) {
            const {message} = error.response.data.data;
            alert(message);
        }

        return Promise.reject(error);
    }
);

const root = ReactDOM.createRoot(document.getElementById("app"));
root.render(
    <Provider store={configureStore}>
        <App />
        <Alert />
    </Provider>,
);
