import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {insertTypeSubRequestAction} from "../../../redux/typeSub/reducer";
import LayoutRightTemplate from "../layout/right";
import getQueryString from "../../../utils/getQueryString";

const AddTypeSubTemplate = () => {
    const {typeSeq} = getQueryString();
    const dispatch = useDispatch();
    const [name, onChangeName, setName] = useInput('');
    const [showYn, onChangeShowYn, setShowYn] = useInput(0);
    const [sort, onChangeSort, setSort] = useInput(0);

    const onSubmit = useCallback(() => {
        if (!name) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(insertTypeSubRequestAction({name, showYn, typeSeq, sort}));
    }, [name, showYn, typeSeq, sort]);

    return (
        <LayoutRightTemplate title={'하위 타입 등록'} clickTitle={'등록'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'}> 이름 </Label>
                <Input typeSub={"text"} theme={'normal'} value={name} onChange={onChangeName}/>

                <Label fontWeight={'bold'}> 정렬 </Label>
                <Input type={"number"} theme={'normal'} value={sort} onChange={onChangeSort}/>

                <Label fontWeight={'bold'}> 공개 여부 </Label>
                <Select options={[{value: '0', text: '비공개'}, {value: '1', text: '공개'}]} value={showYn}
                        onChange={onChangeShowYn}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .jodit-react-container,
  & label {
    margin-top: 20px;
  }

  & input, select, textarea {
    margin-top: 10px;
  }
`

export default AddTypeSubTemplate;
