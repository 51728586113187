import styled from "styled-components";

const Button = ({children, ...props}) => {
    return (
        <ThemeButtonWrap {...props}>
            {children}
        </ThemeButtonWrap>
    )
};

const ButtonWrap = styled.button`
  width: 100%;
  font-weight: 600;
  display: ${({display}) => display};
  align-items: ${({alignItems}) => alignItems};
  justify-content: ${({justifyContent}) => justifyContent};
  max-width: ${({maxWidth}) => maxWidth};
  max-height: ${({maxHeight}) => maxHeight};
  margin: ${({margin}) => margin};
  float: ${({float}) => float};
  font-size: ${({fontSize}) => fontSize};
  text-align: ${({textAlign}) => textAlign};
  height: ${({size}) => {
    switch (size) {
      case 'xs':
        return '35px';
      case 'sm':
        return '45px';
      case 'md':
        return '60px';
      case 'full':
        return '100%';
    }
  }};
  border-radius: ${({shape}) => {
    switch (shape) {
      default:
      case 'rectangle':
        return '6px;';
      case 'oval':
        return '23px;';
      case 'circle':
        return '100%;';
    }
  }};
`;

const ThemeButtonWrap = styled(ButtonWrap)`
  ${({color}) => {
    switch (color) {
      case 'navy':
        return `
            background-color: #4169E1FF;
            color: #fff;
        `;
      case 'white':
        return `
            background-color: #fff;
            color: #000;
            border: 1px solid #eee;
            font-weight: 400;
            padding: 10px;
        `;
      default:
        return;
    }
  }};
`

export default Button;
