import styled from "styled-components";

const Input = ({...props}) => {
    return (
        <ThemeInputWrap {...props} />
    )
}

const InputWrap = styled.input`
  border: 0;
  font-size: 14px;
  margin: ${({margin}) => margin};
`;

const ThemeInputWrap = styled(InputWrap)`
  ${({theme}) => {
    switch (theme) {
      case 'normal':
        return `
            width: 100%;
            height: 45px;
            padding: 10px;
            border: 1px solid #eee;
            border-radius: 6px;
        `;
      default:
        return;
    }
  }};
`

export default Input;
