import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Button from "../../atoms/button";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {updateQuestionRequestAction} from "../../../redux/question/reducer";
import LayoutRightTemplate from "../layout/right";

const EditQuestionTemplate = () => {
    const dispatch = useDispatch();
    const item = useSelector(((state) => state.question.question), shallowEqual);
    const [seq, onChangeSeq, setSeq] = useInput('');
    const [question, onChangeQuestion, setQuestion] = useInput('');
    const [questionTranslate, onChangeQuestionTranslate, setQuestionTranslate] = useInput('');
    const [feedback, onChangeFeedback, setFeedback] = useInput('');
    const [showYn, onChangeShowYn, setShowYn] = useInput(0);

    useEffect(() => {
        setSeq(item.seq);
        setQuestion(item.question);
        setQuestionTranslate(item.questionTranslate);
        setFeedback(item.feedback);
        setShowYn(item.showYn);
    }, [item]);

    const onSubmit = useCallback(() => {
        if (!question || !questionTranslate || !feedback) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(updateQuestionRequestAction({question, questionTranslate, feedback, showYn, seq}));
    }, [question, questionTranslate, feedback, showYn, seq]);

    return (
        <LayoutRightTemplate title={'문제 수정'} clickTitle={'수정'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'}> 문제 </Label>
                <Input type={"text"} theme={'normal'} value={question} onChange={onChangeQuestion}/>

                <Label fontWeight={'bold'}> 문제 번역 </Label>
                <Input type={"text"} theme={'normal'} value={questionTranslate} onChange={onChangeQuestionTranslate}/>

                <Label fontWeight={'bold'}> 피드백 </Label>
                <Input type={"text"} theme={'normal'} value={feedback} onChange={onChangeFeedback}/>

                <Label fontWeight={'bold'}> 공개 여부 </Label>
                <Select options={[{value: '0', text: '비공개'}, {value: '1', text: '공개'}]} value={showYn}
                        onChange={onChangeShowYn}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  & label, button {
    margin-top: 20px;
  }

  & input, select {
    margin-top: 10px;
  }
`

export default EditQuestionTemplate;
