import {
    DELETE_QUESTION_FAILURE,
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    INSERT_QUESTION_FAILURE,
    INSERT_QUESTION_REQUEST,
    INSERT_QUESTION_SUCCESS, LOAD_QUESTION_FAILURE,
    LOAD_QUESTION_REQUEST, LOAD_QUESTION_SUCCESS,
    LOAD_QUESTIONS_FAILURE,
    LOAD_QUESTIONS_REQUEST,
    LOAD_QUESTIONS_SUCCESS,
    UPDATE_QUESTION_FAILURE, UPDATE_QUESTION_REQUEST, UPDATE_QUESTION_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    question: {},
    questions: [],
    loadQuestionsLoading: false,
    loadQuestionsDone: false,
    loadQuestionsError: false,
    loadQuestionLoading: false,
    loadQuestionDone: false,
    loadQuestionError: false,
    insertQuestionLoading: false,
    insertQuestionDone: false,
    insertQuestionError: false,
    deleteQuestionLoading: false,
    deleteQuestionDone: false,
    deleteQuestionError: false,
}

export const loadQuestionsRequestAction = (data) => {
    return {
        type: LOAD_QUESTIONS_REQUEST,
        data,
    }
}

export const loadQuestionRequestAction = (data) => {
    return {
        type: LOAD_QUESTION_REQUEST,
        data,
    }
}

export const updateQuestionRequestAction = (data) => {
    return {
        type: UPDATE_QUESTION_REQUEST,
        data,
    }
}

export const insertQuestionRequestAction = (data) => {
    return {
        type: INSERT_QUESTION_REQUEST,
        data
    }
}

export const deleteQuestionRequestAction = (data) => {
    return {
        type: DELETE_QUESTION_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_QUESTION_REQUEST:
            draft.loadQuestionLoading = true;
            draft.loadQuestionDone = false;
            draft.loadQuestionError = null;
            break;
        case LOAD_QUESTION_SUCCESS:
            draft.loadQuestionLoading = false;
            draft.loadQuestionDone = true;
            draft.question = action.data.data;
            break;
        case LOAD_QUESTION_FAILURE:
            draft.loadQuestionLoading = false;
            draft.loadQuestionDone = false;
            draft.loadQuestionError = action.data;
            break;
        case LOAD_QUESTIONS_REQUEST:
            draft.loadQuestionsLoading = true;
            draft.loadQuestionsDone = false;
            draft.loadQuestionsError = null;
            break;
        case LOAD_QUESTIONS_SUCCESS:
            draft.loadQuestionsLoading = false;
            draft.loadQuestionsDone = true;
            draft.questions = action.data.data;
            break;
        case LOAD_QUESTIONS_FAILURE:
            draft.loadQuestionsLoading = false;
            draft.loadQuestionsDone = false;
            draft.loadQuestionsError = action.data;
            break;
        case INSERT_QUESTION_REQUEST:
            draft.insertQuestionLoading = true;
            draft.insertQuestionDone = false;
            draft.insertQuestionError = null;
            break;
        case INSERT_QUESTION_SUCCESS:
            draft.insertQuestionLoading = false;
            draft.insertQuestionDone = true;
            break;
        case INSERT_QUESTION_FAILURE:
            draft.insertQuestionLoading = false;
            draft.insertQuestionDone = false;
            draft.insertQuestionError = action.data;
            break;
        case UPDATE_QUESTION_REQUEST:
            draft.updateQuestionLoading = true;
            draft.updateQuestionDone = false;
            draft.updateQuestionError = null;
            break;
        case UPDATE_QUESTION_SUCCESS:
            draft.updateQuestionLoading = false;
            draft.updateQuestionDone = true;
            break;
        case UPDATE_QUESTION_FAILURE:
            draft.updateQuestionLoading = false;
            draft.updateQuestionDone = false;
            draft.updateQuestionError = action.data;
            break;
        case DELETE_QUESTION_REQUEST:
            draft.deleteQuestionLoading = true;
            draft.deleteQuestionDone = false;
            draft.deleteQuestionError = null;
            break;
        case DELETE_QUESTION_SUCCESS:
            draft.deleteQuestionLoading = false;
            draft.deleteQuestionDone = true;
            break;
        case DELETE_QUESTION_FAILURE:
            draft.deleteQuestionLoading = false;
            draft.deleteQuestionDone = false;
            draft.deleteQuestionError = action.data;
            break;
    }
});

export default reducer;

