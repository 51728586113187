import {combineReducers} from "redux";
import user from "./redux/user/reducer";
import alert from "./redux/alert/reducer";
import layout from "./redux/layout/reducer";
import question from "./redux/question/reducer";
import word from "./redux/word/reducer";
import type from "./redux/type/reducer";
import typeSub from "./redux/typeSub/reducer";
import board from "./redux/board/reducer";

const rootReducer = (state, action) => {
    switch (action.type) {
        default: {
            const combinedReducer = combineReducers({
                user,
                alert,
                layout,
                question,
                word,
                type,
                typeSub,
                board
            });
            return combinedReducer(state, action);
        }
    }
};

export default rootReducer;
