import styled from "styled-components";

const Select = ({options, margin, onChange, ...props}) => {
    return (
        <SelectWrap margin={margin} onChange={onChange} {...props}>
            {
                options.map((v, index) => {
                    return <option key={index} value={v.value}> {v.text} </option>
                })
            }
        </SelectWrap>
    )
}

const SelectWrap = styled.select`
  margin: ${({margin}) => margin};
  max-width: ${({maxWidth}) => maxWidth };
  max-height: ${({maxHeight}) => maxHeight };
  height: 45px;
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  cursor: pointer;
  //box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
`

export default Select;
