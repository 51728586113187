import AddWordTemplate from "../../components/templates/word/add";
import LayoutTemplate from "../../components/templates/layout/left";
import {useCallback, useEffect} from "react";
import {useDispatch} from "react-redux";
import {loadTypeSubsRequestAction} from "../../redux/typeSub/reducer";

const AddWord = () => {
    const dispatch = useDispatch();

    const onLoad = useCallback(() => {
        dispatch(loadTypeSubsRequestAction());
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <AddWordTemplate />
        </LayoutTemplate>
    )
}

export default AddWord;
