import WordTemplate from "../../components/templates/word";
import {useCallback, useEffect} from "react";
import {loadWordsRequestAction} from "../../redux/word/reducer";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";
import {loadTypeSubsRequestAction} from "../../redux/typeSub/reducer";

const Word = () => {
    const dispatch = useDispatch();
    const onLoad = useCallback(() => {
        dispatch(loadWordsRequestAction());
        dispatch(loadTypeSubsRequestAction());
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <WordTemplate />
        </LayoutTemplate>
    );
};

export default Word;
