import EditWordTemplate from "../../components/templates/word/edit";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadWordRequestAction} from "../../redux/word/reducer";
import LayoutTemplate from "../../components/templates/layout/left";
import {loadTypeSubsRequestAction} from "../../redux/typeSub/reducer";

const EditWord = () => {
    const dispatch = useDispatch();
    const {seq} = useParams();

    const onLoad = useCallback(() => {
        dispatch(loadWordRequestAction({seq}));
        dispatch(loadTypeSubsRequestAction());
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <EditWordTemplate />
        </LayoutTemplate>
    );
};

export default EditWord;
