import useInput from "../../hooks/useInput";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import Button from "../../components/atoms/button";
import Input from "../../components/atoms/input";
import {useCallback} from "react";
import Label from "../../components/atoms/label";
import logo from '../../assets/images/logo.png';
import background from '../../assets/images/background.png';
import {loginRequestAction} from "../../redux/user/reducer";

const Login = () => {
    const dispatch = useDispatch();
    const [id, onChangeId, setId] = useInput('');
    const [password, onChangePassword, setPassword] = useInput('');

    const onLogin = useCallback(() => {
            dispatch(loginRequestAction({id, password, role: 'Admin'}));
    }, [id, password]);

    return (
        <Wrap>
            <Logo src={logo} alt={'logo'}/>

            <LoginWrap>
                <LoginLeftWrap>
                    <Background src={background} alt={'background'}/>
                </LoginLeftWrap>

                <LoginRightWrap>
                    <Label fontSize={'28px'} fontWeight={'500'}> Welcome Back </Label>
                    <Label fontSize={'14px'} color={'#bbb'} margin={'10px 0 0 0'}>Enter your credentials to access your account</Label>
                    <Input type={"email"} theme={'normal'} value={id} onChange={onChangeId} placeholder={'Enter your id'} margin={'20px 0 0 0'}/>
                    <Input type={"password"} theme={'normal'} value={password} onChange={onChangePassword} placeholder={'Enter your password'} margin={'10px 0 20px 0'}/>
                    <Button size={'sm'} color={'navy'} onClick={onLogin}>Sign in</Button>
                </LoginRightWrap>
            </LoginWrap>
        </Wrap>
    )
}

const Wrap = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LoginWrap = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const LoginLeftWrap = styled.div`
  width: 100%;
  max-width: 60%;
  background-color: rgb(241, 243, 248);

  @media screen and (max-width: 768px) {
    & {
      max-width: 30%;
    }
  }
`

const LoginRightWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
  background-color: rgb(241, 243, 248);
  gap: 10px;
  padding: 0 10vw;
`

const Logo = styled.img`
  position: absolute;
  z-index: 2;
  top: 2vw;
  left: 2vw;
  width: 150px;

  @media screen and (max-width: 768px) {
    top: 4vw;
    left: 4vw;
    width: 50px;
  }
`;

const Background = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  background-image: ${({src}) => `url(${src})`};
  background-position: left top;
  background-size: cover;
  box-shadow: rgb(0 0 0 / 15%) 0 0 6px 0;
`

export default Login;
