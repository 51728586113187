import AddBoardTemplate from "../../components/templates/board/add";
import LayoutTemplate from "../../components/templates/layout/left";

const AddBoard = () => {
    return (
        <LayoutTemplate>
            <AddBoardTemplate />
        </LayoutTemplate>
    )
}

export default AddBoard;
