import BoardTemplate from "../../components/templates/board";
import {useCallback, useEffect} from "react";
import {loadBoardsRequestAction} from "../../redux/board/reducer";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";

const Board = () => {
    const dispatch = useDispatch();
    const onLoadBoard = useCallback(() => {
        dispatch(loadBoardsRequestAction());
    }, []);

    useEffect(() => {
        onLoadBoard();
    }, []);

    return (
        <LayoutTemplate>
            <BoardTemplate />
        </LayoutTemplate>
    );
};

export default Board;
