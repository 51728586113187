import TypeSubTemplate from "../../components/templates/typeSub";
import {useCallback, useEffect} from "react";
import {loadTypeSubsByTypeRequestAction} from "../../redux/typeSub/reducer";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";
import getQueryString from "../../utils/getQueryString";

const TypeSub = () => {
    const {typeSeq} = getQueryString();
    const dispatch = useDispatch();
    const onLoad = useCallback(() => {
        dispatch(loadTypeSubsByTypeRequestAction({typeSeq}));
    }, []);

    useEffect(() => {
        onLoad();
    }, []);

    return (
        <LayoutTemplate>
            <TypeSubTemplate />
        </LayoutTemplate>
    );
};

export default TypeSub;
