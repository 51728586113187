import AddTypeSubTemplate from "../../components/templates/typeSub/add";
import LayoutTemplate from "../../components/templates/layout/left";

const AddTypeSub = () => {
    return (
        <LayoutTemplate>
            <AddTypeSubTemplate />
        </LayoutTemplate>
    )
}

export default AddTypeSub;
