import Label from "../../atoms/label";
import styled from "styled-components";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import Button from "../../atoms/button";
import File from "../../molecules/file";
import {useCallback, useState} from "react";
import {foldLayoutLeftRequestAction} from "../../../redux/layout/reducer";

const LayoutRightTemplate = ({
                                 children,
                                 title,
                                 clickTitle,
                                 onClick,
                                 onDelete,
                                 onXlsxDownload,
                                 onXlsxUpload,
                                 isButtonBottom,
                                 isLayoutContent
                             }) => {
    const dispatch = useDispatch();
    const onFoldSidebar = useCallback(() => {
        dispatch(foldLayoutLeftRequestAction());
    }, []);

    return (
        <>
            {
                !isLayoutContent ?
                    <LayoutContentWrap>
                        <LayoutSideBarFoldWrap>
                            <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 24 24"
                                 onClick={onFoldSidebar}
                                 fill="none" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                 strokeLinejoin="round" className="feather feather-bar-chart-2" rotate="90"
                                 style={{transform: 'rotate(90deg)'}}>
                                <line x1="18" y1="20" x2="18" y2="10"/>
                                <line x1="12" y1="20" x2="12" y2="4"/>
                                <line x1="6" y1="20" x2="6" y2="14"/>
                            </svg>
                        </LayoutSideBarFoldWrap>

                        <LayoutContentTitleWrap isButtonBottom={isButtonBottom}>
                            <Label> {title} </Label>

                            <LayoutContentButtonWrap>
                                {
                                    onXlsxDownload &&
                                    <Button shape={'rectangle'} size={'xs'} color={'navy'} maxWidth={'150px'}
                                            onClick={onXlsxDownload}> 엑셀 템플릿 다운로드 </Button>
                                }
                                {
                                    onXlsxUpload &&
                                    <File htmlFor={'file'} shape={'rectangle'} size={'xs'} color={'navy'} maxWidth={'100px'}
                                          display={'flex'} alignItems={'center'} justifyContent={'center'}
                                          onChange={onXlsxUpload}> 엑셀 업로드 </File>
                                }
                                {
                                    onClick &&
                                    <Button shape={'rectangle'} size={'xs'} color={'navy'} maxWidth={'100px'}
                                            onClick={onClick}>{clickTitle}</Button>
                                }
                                {
                                    onDelete &&
                                    <Button shape={'rectangle'} size={'xs'} color={'red'} maxWidth={'100px'}
                                            onClick={onDelete}>삭제</Button>
                                }
                            </LayoutContentButtonWrap>
                        </LayoutContentTitleWrap>

                        <LayoutContentChildWrap>
                            {children}
                        </LayoutContentChildWrap>
                    </LayoutContentWrap> : <> {children} </>
            }
        </>
    )
};

const LayoutContentButtonWrap = styled.div`
  flex: 1;
  display: flex;
  justify-content: end;
  gap: 10px;
`

const LayoutSideBarFoldWrap = styled.div`
  display: flex;

  & svg {
    cursor: pointer;
  }
`

const LayoutContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 100%;
`

const LayoutContentChildWrap = styled.div`
  height: 100%;
  padding: 20px;
  display: flex;
  background-color: #fff;
  border-radius: 12px;
  flex-direction: column;
  //justify-content: space-between;
`;

const LayoutContentTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  gap: 10px;
  order: ${({isButtonBottom}) => isButtonBottom ? 1 : 0};

  & > label {
    flex: 1;
    font-size: 24px;
  }
`;

export default LayoutRightTemplate;
