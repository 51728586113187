import styled from "styled-components";

const Tag = ({children, ...props}) => {
    return (
        <TagWrap {...props}>
            {children}
        </TagWrap>
    )
}

const TagWrap = styled.label`
  display: flex;
  margin: 0 auto;
  padding: 4px;
  border-radius: 32px;
  font-size: 13px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;

  width: ${({size}) => {
    switch (size) {
      case 'xs':
        return '45px';
      case 'sm':
      default:
        return '60px';
      case 'md':
        return '75px';
      case 'lg':
        return '90px';
      case 'full':
        return '100%';
    }
  }};
  
  ${({theme}) => {
    switch (theme) {
      case 'red':
        return `
           background-color: rgb(220 38 38);
           color: #fff;
        `;
      case 'navy':
        return `
           background-color: #4169E1FF;
           color: #fff;
        `;
      case 'green':
        return `
           background-color: #3cb371;
           color: #fff;
        `;
      case 'orange':
        return `
           background-color: #ff8c00;
           color: #fff;
        `;
      case 'violet':
        return `
           background-color: #9400d3;
           color: #fff;
        `;
      default:
        return;
    }
  }};
`;

export default Tag;
