export const LOAD_QUESTIONS_REQUEST = 'LOAD_QUESTIONS_REQUEST';
export const LOAD_QUESTIONS_SUCCESS = 'LOAD_QUESTIONS_SUCCESS';
export const LOAD_QUESTIONS_FAILURE = 'LOAD_QUESTIONS_FAILURE';

export const LOAD_QUESTION_REQUEST = 'LOAD_QUESTION_REQUEST';
export const LOAD_QUESTION_SUCCESS = 'LOAD_QUESTION_SUCCESS';
export const LOAD_QUESTION_FAILURE = 'LOAD_QUESTION_FAILURE';

export const INSERT_QUESTION_REQUEST = 'INSERT_QUESTION_REQUEST';
export const INSERT_QUESTION_SUCCESS = 'INSERT_QUESTION_SUCCESS';
export const INSERT_QUESTION_FAILURE = 'INSERT_QUESTION_FAILURE';

export const UPDATE_QUESTION_REQUEST = 'UPDATE_QUESTION_REQUEST';
export const UPDATE_QUESTION_SUCCESS = 'UPDATE_QUESTION_SUCCESS';
export const UPDATE_QUESTION_FAILURE = 'UPDATE_QUESTION_FAILURE';

export const DELETE_QUESTION_REQUEST = 'DELETE_QUESTION_REQUEST';
export const DELETE_QUESTION_SUCCESS = 'DELETE_QUESTION_SUCCESS';
export const DELETE_QUESTION_FAILURE = 'DELETE_QUESTION_FAILURE';
