import {
    DELETE_TYPESUB_FAILURE,
    DELETE_TYPESUB_REQUEST,
    DELETE_TYPESUB_SUCCESS,
    INSERT_TYPESUB_FAILURE,
    INSERT_TYPESUB_REQUEST,
    INSERT_TYPESUB_SUCCESS, LOAD_TYPESUB_FAILURE,
    LOAD_TYPESUB_REQUEST, LOAD_TYPESUB_SUCCESS, LOAD_TYPESUBS_BY_TYPE_FAILURE, LOAD_TYPESUBS_BY_TYPE_REQUEST, LOAD_TYPESUBS_BY_TYPE_SUCCESS,
    LOAD_TYPESUBS_FAILURE,
    LOAD_TYPESUBS_REQUEST,
    LOAD_TYPESUBS_SUCCESS,
    UPDATE_TYPESUB_FAILURE, UPDATE_TYPESUB_REQUEST, UPDATE_TYPESUB_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    typeSub: {},
    typeSubs: [],
    typeSubsByType: [],
    loadTypeSubsLoading: false,
    loadTypeSubsDone: false,
    loadTypeSubsError: false,
    loadTypeSubsByTypeLoading: false,
    loadTypeSubsByTypeDone: false,
    loadTypeSubsByTypeError: false,
    loadTypeSubLoading: false,
    loadTypeSubDone: false,
    loadTypeSubError: false,
    insertTypeSubLoading: false,
    insertTypeSubDone: false,
    insertTypeSubError: false,
    deleteTypeSubLoading: false,
    deleteTypeSubDone: false,
    deleteTypeSubError: false,
}

export const loadTypeSubsRequestAction = (data) => {
    return {
        type: LOAD_TYPESUBS_REQUEST,
        data,
    }
}

export const loadTypeSubsByTypeRequestAction = (data) => {
    return {
        type: LOAD_TYPESUBS_BY_TYPE_REQUEST,
        data,
    }
}

export const loadTypeSubRequestAction = (data) => {
    return {
        type: LOAD_TYPESUB_REQUEST,
        data,
    }
}

export const updateTypeSubRequestAction = (data) => {
    return {
        type: UPDATE_TYPESUB_REQUEST,
        data,
    }
}

export const insertTypeSubRequestAction = (data) => {
    return {
        type: INSERT_TYPESUB_REQUEST,
        data
    }
}

export const deleteTypeSubRequestAction = (data) => {
    return {
        type: DELETE_TYPESUB_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_TYPESUB_REQUEST:
            draft.loadTypeSubLoading = true;
            draft.loadTypeSubDone = false;
            draft.loadTypeSubError = null;
            break;
        case LOAD_TYPESUB_SUCCESS:
            draft.loadTypeSubLoading = false;
            draft.loadTypeSubDone = true;
            draft.typeSub = action.data.data;
            break;
        case LOAD_TYPESUB_FAILURE:
            draft.loadTypeSubLoading = false;
            draft.loadTypeSubDone = false;
            draft.loadTypeSubError = action.data;
            break;
        case LOAD_TYPESUBS_REQUEST:
            draft.loadTypeSubsLoading = true;
            draft.loadTypeSubsDone = false;
            draft.loadTypeSubsError = null;
            break;
        case LOAD_TYPESUBS_SUCCESS:
            draft.loadTypeSubsLoading = false;
            draft.loadTypeSubsDone = true;
            draft.typeSubs = action.data.data;
            break;
        case LOAD_TYPESUBS_FAILURE:
            draft.loadTypeSubsLoading = false;
            draft.loadTypeSubsDone = false;
            draft.loadTypeSubsError = action.data;
            break;
        case LOAD_TYPESUBS_BY_TYPE_REQUEST:
            draft.loadTypeSubsByTypeLoading = true;
            draft.loadTypeSubsByTypeDone = false;
            draft.loadTypeSubsByTypeError = null;
            break;
        case LOAD_TYPESUBS_BY_TYPE_SUCCESS:
            draft.loadTypeSubsByTypeLoading = false;
            draft.loadTypeSubsByTypeDone = true;
            draft.typeSubsByType = action.data.data;
            break;
        case LOAD_TYPESUBS_BY_TYPE_FAILURE:
            draft.loadTypeSubsByTypeLoading = false;
            draft.loadTypeSubsByTypeDone = false;
            draft.loadTypeSubsByTypeError = action.data;
            break;
        case INSERT_TYPESUB_REQUEST:
            draft.insertTypeSubLoading = true;
            draft.insertTypeSubDone = false;
            draft.insertTypeSubError = null;
            break;
        case INSERT_TYPESUB_SUCCESS:
            draft.insertTypeSubLoading = false;
            draft.insertTypeSubDone = true;
            break;
        case INSERT_TYPESUB_FAILURE:
            draft.insertTypeSubLoading = false;
            draft.insertTypeSubDone = false;
            draft.insertTypeSubError = action.data;
            break;
        case UPDATE_TYPESUB_REQUEST:
            draft.updateTypeSubLoading = true;
            draft.updateTypeSubDone = false;
            draft.updateTypeSubError = null;
            break;
        case UPDATE_TYPESUB_SUCCESS:
            draft.updateTypeSubLoading = false;
            draft.updateTypeSubDone = true;
            break;
        case UPDATE_TYPESUB_FAILURE:
            draft.updateTypeSubLoading = false;
            draft.updateTypeSubDone = false;
            draft.updateTypeSubError = action.data;
            break;
        case DELETE_TYPESUB_REQUEST:
            draft.deleteTypeSubLoading = true;
            draft.deleteTypeSubDone = false;
            draft.deleteTypeSubError = null;
            break;
        case DELETE_TYPESUB_SUCCESS:
            draft.deleteTypeSubLoading = false;
            draft.deleteTypeSubDone = true;
            break;
        case DELETE_TYPESUB_FAILURE:
            draft.deleteTypeSubLoading = false;
            draft.deleteTypeSubDone = false;
            draft.deleteTypeSubError = action.data;
            break;
    }
});

export default reducer;

