import {useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useRowSelect} from "react-table";
import styled from "styled-components";
import {useCallback, useEffect, useMemo, useState} from "react";
import {matchSorter} from "match-sorter";
import pagination from "../../../utils/common/pagination";
import Select from "../select";
import useInput from "../../../hooks/useInput";
import history from "../../../utils/history";
import getQueryString from "../../../utils/getQueryString";

const GlobalFilter = ({preGlobalFilteredRows, globalFilter, setGlobalFilter}) => {
    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200)

    return (
        <SearchInput
            value={value || ""}
            onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
            }}
            placeholder={`Search...`}
        />
    )
}

const DefaultColumnFilter = ({column: {filterValue, preFilteredRows, setFilter}}) => {
    return (
        <DefaultSearchInput
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Search...`}
        />
    )
}

const Table = ({columns, data, mainSearch, subSearch, leftButtons}) => {
    const [searchRow, onChangeSearchRow, setSearchRow] = useInput(columns.filter(i => i.Header !== '')[0].accessor);

    const defaultColumn = useMemo(() => ({
        Filter: DefaultColumnFilter,
    }), []);

    const fuzzyTextFilterFn = (rows, id, filterValue) => {
        return matchSorter(rows, filterValue, {keys: [row => row.values[id]]})
    }

    const filterTypes = useMemo(() => ({
        fuzzyText: fuzzyTextFilterFn,
        text: (rows, id, filterValue) => {
            return rows.filter(row => {
                const rowValue = row.values[id]
                return rowValue !== undefined
                    ? String(rowValue)
                        .toLowerCase()
                        .startsWith(String(filterValue).toLowerCase())
                    : true
            })
        },
    }), []);

    const queryString = getQueryString();

    const _GlobalFilterFunction = useCallback((rows, ids, query) => {
        if (Number(queryString.page) !== 1) history.replace(`?page=1`);

        return rows.filter((row) => {
            if (row.values[searchRow]?.props) {
                return row.values[searchRow]?.props.children.toString().includes(query);
            } else {
                return row.values[searchRow]?.toString().includes(query);
            }
        });
    }, [searchRow]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        rows,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        selectedFlatRows,
        state: {pageIndex, pageSize},
    } = useTable({
        columns,
        data,
        initialState: {pageIndex: queryString.page ? Number(queryString.page) - 1 : 0},
        defaultColumn,
        filterTypes,
        globalFilter: _GlobalFilterFunction,
    }, useFilters, useGlobalFilter, usePagination, useRowSelect);

    const [paginate, setPaginate] = useState(pagination(rows.length, (pageIndex + 1) || 1));

    useEffect(() => {
        setPaginate(pagination(rows.length, (pageIndex + 1) || 1));
    }, [pageIndex, rows.length]);

    return (
        <>
            <TableHeaderWrap>
                {
                    leftButtons && leftButtons(selectedFlatRows)
                }

                {
                    mainSearch &&
                    <GlobalFilterWrap>
                        <Select
                            options={columns.filter(i => i.Header !== '' && i.id !== 'check').map(i => ({value: i.accessor, text: i.Header}))}
                            value={searchRow} onChange={onChangeSearchRow}/>
                        <GlobalFilter
                            preGlobalFilteredRows={preGlobalFilteredRows}
                            globalFilter={state.globalFilter}
                            setGlobalFilter={setGlobalFilter}
                        />
                    </GlobalFilterWrap>
                }
            </TableHeaderWrap>

            <TableWrap {...getTableProps()}>
                <thead>
                {
                    headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column, index) => (
                                <th {...column.getHeaderProps()} key={index}>
                                    {column.render('Header')}
                                    {
                                        subSearch ?
                                            <div> {column.id !== 'xlsx' && column.id !== 'action' && column.id !== 'check' ? column.render('Filter') : null} </div> : ''
                                    }
                                </th>
                            ))}
                        </tr>
                    ))
                }
                </thead>
                <tbody {...getTableBodyProps()}>
                {
                    page.length > 0 ?
                        page.map((row) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell, index) => (
                                        <td {...cell.getCellProps()} key={index} style={{width: cell.column.width}}>
                                            {cell.render("Cell")}
                                        </td>
                                    ))}
                                </tr>
                            );
                        })
                        :
                        <tr>
                            <td colSpan={headerGroups[0].headers.length} style={{color: '#aaa'}}>입력된 데이터가 없습니다.</td>
                        </tr>
                }
                </tbody>
            </TableWrap>

            <PaginationWrap>
                <PaginationPrevWrap disabled={paginate.preventPrevPage}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         onClick={() => {
                             if (!paginate.preventPrevPage) {
                                 gotoPage(paginate.bigPrevPage - 1);
                                 history.replace(`?page=${paginate.bigPrevPage}`);
                             }
                         }}
                         strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="11 17 6 12 11 7"></polyline>
                        <polyline points="18 17 13 12 18 7"></polyline>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                         onClick={() => {
                             if (!paginate.preventPrevPage) {
                                 previousPage();
                                 history.replace(`?page=${pageIndex}`);
                             }
                         }}
                         stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-chevron-left">
                        <polyline points="15 18 9 12 15 6"/>
                    </svg>
                </PaginationPrevWrap>

                {
                    paginate.pageBlock.map((i, index) => {
                        return (
                            <PaginationGoToButton onClick={() => {
                                gotoPage(i - 1);
                                history.replace(`?page=${i}`);
                            }}
                                                  active={(pageIndex + 1) === i}
                                                  key={index}> {i} </PaginationGoToButton>
                        )
                    })
                }

                <PaginationNextWrap disabled={paginate.preventNextPage}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                         onClick={() => {
                             if (!paginate.preventNextPage) {
                                 nextPage();
                                 history.replace(`?page=${pageIndex + 2}`);
                             }
                         }}
                         stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"
                         className="feather feather-chevron-right">
                        <polyline points="9 18 15 12 9 6"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                         onClick={() => {
                             if (!paginate.preventNextPage) {
                                 gotoPage(paginate.bigNextPage - 1);
                                 history.replace(`?page=${paginate.bigNextPage}`);
                             }
                         }}
                         strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round">
                        <polyline points="13 17 18 12 13 7"></polyline>
                        <polyline points="6 17 11 12 6 7"></polyline>
                    </svg>
                </PaginationNextWrap>
            </PaginationWrap>
        </>
    );
};

const GlobalFilterWrap = styled.div`
  display: flex;
  gap: 15px;
  justify-content: end;
`

const TableHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > button {
    width: 40px;
  }

  & > div {
    margin-left: auto;
  }
`

const TableWrap = styled.table`
  width: 100%;
  text-align: center;
  border-spacing: 0;

  & th {
    font-weight: 600;
  }

  & th, td {
    padding: 16px 8px;
  }

  & tbody tr:nth-child(odd) {
    background-color: rgb(241, 243, 248);
  }

  //& tr:hover {
  //  background-color: #e7ecf0 !important;
  //}

  @media screen and (max-width: 768px) {
    & th, td {
      padding: 9px;
      min-width: 100px;
    }
  }
`

const PaginationWrap = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding-top: 20px;

  & svg {
    cursor: pointer;
  }
`

const PaginationPrevWrap = styled.div`
  display: flex;
  
  & svg {
    color: ${({disabled}) => disabled ? '#aaa' : '#000'};
     cursor: ${({disabled}) => disabled ? 'initial' : 'pointer'}; 
  }
`

const PaginationNextWrap = styled.div`
  display: flex;
  
  & svg {
    color: ${({disabled}) => disabled ? '#aaa' : '#000'};
     cursor: ${({disabled}) => disabled ? 'initial' : 'pointer'};
  }
`

const PaginationGoToButton = styled.button`
  background-color: ${({active}) => active ? '#4169E1FF' : '#fff'};
  color: ${({active}) => active ? '#fff' : ''};
  width: 30px;
  height: 30px;
  border-radius: 6px;
`

const SearchInput = styled.input`
  height: 45px;
  padding: 0 10px;
  border: 1px solid #eee;
  border-radius: 6px;
`

const DefaultSearchInput = styled.input`
  height: 35px;
  width: 100%;
  margin-top: 10px;
  background-color: #f1f3f8;
  border: 0;
  border-radius: 6px;
  padding: 10px;
`

export default Table;
