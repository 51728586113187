import axios from "axios";
import {
    DELETE_WORD_FAILURE,
    DELETE_WORD_REQUEST,
    DELETE_WORD_SUCCESS, INSERT_EXCEL_WORD_FAILURE, INSERT_EXCEL_WORD_REQUEST, INSERT_EXCEL_WORD_SUCCESS,
    INSERT_WORD_FAILURE,
    INSERT_WORD_REQUEST,
    INSERT_WORD_SUCCESS,
    LOAD_WORD_FAILURE, LOAD_WORD_REQUEST,
    LOAD_WORD_SUCCESS,
    LOAD_WORDS_FAILURE,
    LOAD_WORDS_REQUEST,
    LOAD_WORDS_SUCCESS,
    UPDATE_WORD_FAILURE, UPDATE_WORD_REQUEST, UPDATE_WORD_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function loadWordsAPI() {
    return axios.get('/admin/api/v1/word')
}

function loadWordAPI(data) {
    return axios.get(`/admin/api/v1/word/${data.seq}`)
}

function insertWordAPI(data) {
    return axios.post('/admin/api/v1/word', data)
}

function insertExcelWordAPI(data) {
    return axios.post('/admin/api/v1/word/excel', data)
}

function updateWordAPI(data) {
    return axios.put('/admin/api/v1/word', data);
}

function deleteWordAPI(data) {
    return axios.delete('/admin/api/v1/word', {data})
}

function* loadWords(action) {
    try {
        const result = yield call(loadWordsAPI, action.data);
        yield put({
            type: LOAD_WORDS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORDS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadWord(action) {
    try {
        const result = yield call(loadWordAPI, action.data);
        yield put({
            type: LOAD_WORD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertWord(action) {
    try {
        const result = yield call(insertWordAPI, action.data);
        yield put({
            type: INSERT_WORD_SUCCESS,
            data: result.data,
        });
        history.push('/word');
    } catch (err) {
        yield put({
            type: INSERT_WORD_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* insertExcelWord(action) {
    try {
        const result = yield call(insertExcelWordAPI, action.data);
        yield put({
            type: INSERT_EXCEL_WORD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: INSERT_EXCEL_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* updateWord(action) {
    try {
        const result = yield call(updateWordAPI, action.data);
        yield put({
            type: UPDATE_WORD_SUCCESS,
            data: result.data,
        });
        history.push('/word');
    } catch (err) {
        yield put({
            type: UPDATE_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteWord(action) {
    try {
        const result = yield call(deleteWordAPI, action.data);
        yield put({
            type: DELETE_WORD_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_WORD_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadWords() {
    yield takeLatest(LOAD_WORDS_REQUEST, loadWords);
}

function* watchLoadWord() {
    yield takeLatest(LOAD_WORD_REQUEST, loadWord);
}

function* watchInsertWord() {
    yield takeLatest(INSERT_WORD_REQUEST, insertWord);
}

function* watchInsertExcelWord() {
    yield takeLatest(INSERT_EXCEL_WORD_REQUEST, insertExcelWord);
}

function* watchUpdateWord() {
    yield takeLatest(UPDATE_WORD_REQUEST, updateWord);
}

function* watchDeleteWord() {
    yield takeLatest(DELETE_WORD_REQUEST, deleteWord);
}

export default function* saga() {
    yield all([
        fork(watchLoadWords),
        fork(watchLoadWord),
        fork(watchInsertWord),
        fork(watchInsertExcelWord),
        fork(watchUpdateWord),
        fork(watchDeleteWord),
    ])
}

