import QuestionTemplate from "../../components/templates/question";
import {useCallback, useEffect} from "react";
import {loadQuestionsRequestAction} from "../../redux/question/reducer";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";
import history from "../../utils/history";

const Question = () => {
    const dispatch = useDispatch();
    const onLoadQuestion = useCallback(() => {
        dispatch(loadQuestionsRequestAction());
    }, []);

    useEffect(() => {
        onLoadQuestion();
    }, []);

    return (
        <LayoutTemplate>
            <QuestionTemplate />
        </LayoutTemplate>
    );
};

export default Question;
