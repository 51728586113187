import UserTemplate from "../../components/templates/user";
import {useCallback, useEffect} from "react";
import {loadUsersRequestAction} from "../../redux/user/reducer";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";
import history from "../../utils/history";

const User = () => {
    const dispatch = useDispatch();
    const onLoadUser = useCallback(() => {
        dispatch(loadUsersRequestAction());
    }, []);

    useEffect(() => {
        onLoadUser();
    }, []);

    return (
        <LayoutTemplate>
            <UserTemplate />
        </LayoutTemplate>
    );
};

export default User;
