import AddTypeTemplate from "../../components/templates/type/add";
import LayoutTemplate from "../../components/templates/layout/left";

const AddType = () => {
    return (
        <LayoutTemplate>
            <AddTypeTemplate />
        </LayoutTemplate>
    )
}

export default AddType;
