import axios from "axios";
import {
    DELETE_TYPE_FAILURE,
    DELETE_TYPE_REQUEST,
    DELETE_TYPE_SUCCESS,
    INSERT_TYPE_FAILURE,
    INSERT_TYPE_REQUEST,
    INSERT_TYPE_SUCCESS,
    LOAD_TYPE_FAILURE, LOAD_TYPE_REQUEST,
    LOAD_TYPE_SUCCESS,
    LOAD_TYPES_FAILURE,
    LOAD_TYPES_REQUEST,
    LOAD_TYPES_SUCCESS,
    UPDATE_TYPE_FAILURE, UPDATE_TYPE_REQUEST, UPDATE_TYPE_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function loadTypesAPI() {
    return axios.get('/admin/api/v1/type')
}

function loadTypeAPI(data) {
    return axios.get(`/admin/api/v1/type/${data.seq}`)
}

function insertTypeAPI(data) {
    return axios.post('/admin/api/v1/type', data)
}

function updateTypeAPI(data) {
    return axios.put('/admin/api/v1/type', data);
}

function deleteTypeAPI(data) {
    return axios.delete('/admin/api/v1/type', {data})
}

function* loadTypes(action) {
    try {
        const result = yield call(loadTypesAPI, action.data);
        yield put({
            type: LOAD_TYPES_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TYPES_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadType(action) {
    try {
        const result = yield call(loadTypeAPI, action.data);
        yield put({
            type: LOAD_TYPE_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TYPE_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertType(action) {
    try {
        const result = yield call(insertTypeAPI, action.data);
        yield put({
            type: INSERT_TYPE_SUCCESS,
            data: result.data,
        });
        history.push('/type');
    } catch (err) {
        yield put({
            type: INSERT_TYPE_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateType(action) {
    try {
        const result = yield call(updateTypeAPI, action.data);
        yield put({
            type: UPDATE_TYPE_SUCCESS,
            data: result.data,
        });
        history.push('/type');
    } catch (err) {
        yield put({
            type: UPDATE_TYPE_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteType(action) {
    try {
        const result = yield call(deleteTypeAPI, action.data);
        yield put({
            type: DELETE_TYPE_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_TYPE_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadTypes() {
    yield takeLatest(LOAD_TYPES_REQUEST, loadTypes);
}

function* watchLoadType() {
    yield takeLatest(LOAD_TYPE_REQUEST, loadType);
}

function* watchInsertType() {
    yield takeLatest(INSERT_TYPE_REQUEST, insertType);
}

function* watchUpdateType() {
    yield takeLatest(UPDATE_TYPE_REQUEST, updateType);
}

function* watchDeleteType() {
    yield takeLatest(DELETE_TYPE_REQUEST, deleteType);
}

export default function* saga() {
    yield all([
        fork(watchLoadTypes),
        fork(watchLoadType),
        fork(watchInsertType),
        fork(watchUpdateType),
        fork(watchDeleteType),
    ])
}

