import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {updateTypeRequestAction} from "../../../redux/type/reducer";
import LayoutRightTemplate from "../layout/right";

const EditTypeTemplate = () => {
    const dispatch = useDispatch();
    const type = useSelector(((state) => state.type.type), shallowEqual);
    const [seq, setSeq] = useState('');
    const [name, onChangeName, setName] = useInput('');
    const [showYn, onChangeShowYn, setShowYn] = useInput(0);
    const [showRole, onChangeShowRole, setShowRole] = useInput(0);
    const [sort, onChangeSort, setSort] = useInput(0);

    useEffect(() => {
        setSeq(type.seq);
        setName(type.name);
        setShowYn(type.showYn);
        setShowRole(type.showRole);
        setSort(type.sort);
    }, [type]);

    const onSubmit = useCallback(() => {
        if (!name) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(updateTypeRequestAction({name, seq, showYn, showRole, sort}));
    }, [name, seq, showYn, showRole, sort]);

    return (
        <LayoutRightTemplate title={'타입 수정'} clickTitle={'수정'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'}> 이름 </Label>
                <Input type={"text"} theme={'normal'} value={name} onChange={onChangeName}/>

                <Label fontWeight={'bold'}> 정렬 </Label>
                <Input type={"number"} theme={'normal'} value={sort} onChange={onChangeSort}/>

                <Label fontWeight={'bold'}> 공개 여부 </Label>
                <Select options={[{value: '0', text: '비공개'}, {value: '1', text: '공개'}]} value={showYn}
                        onChange={onChangeShowYn}/>

                <Label fontWeight={'bold'}> 공개 권한 </Label>
                <Select options={[{value: '0', text: '비회원'}, {value: '1', text: '회원'}, {value: '2', text: '정회원'}]} value={showRole}
                        onChange={onChangeShowRole}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .jodit-react-container,
  & label {
    margin-top: 20px;
  }

  & input, select {
    margin-top: 10px;
  }
`

export default EditTypeTemplate;
