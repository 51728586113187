export const LOAD_TYPESUBS_REQUEST = 'LOAD_TYPESUBS_REQUEST';
export const LOAD_TYPESUBS_SUCCESS = 'LOAD_TYPESUBS_SUCCESS';
export const LOAD_TYPESUBS_FAILURE = 'LOAD_TYPESUBS_FAILURE';

export const LOAD_TYPESUBS_BY_TYPE_REQUEST = 'LOAD_TYPESUBS_BY_TYPE_REQUEST';
export const LOAD_TYPESUBS_BY_TYPE_SUCCESS = 'LOAD_TYPESUBS_BY_TYPE_SUCCESS';
export const LOAD_TYPESUBS_BY_TYPE_FAILURE = 'LOAD_TYPESUBS_BY_TYPE_FAILURE';

export const LOAD_TYPESUB_REQUEST = 'LOAD_TYPESUB_REQUEST';
export const LOAD_TYPESUB_SUCCESS = 'LOAD_TYPESUB_SUCCESS';
export const LOAD_TYPESUB_FAILURE = 'LOAD_TYPESUB_FAILURE';

export const INSERT_TYPESUB_REQUEST = 'INSERT_TYPESUB_REQUEST';
export const INSERT_TYPESUB_SUCCESS = 'INSERT_TYPESUB_SUCCESS';
export const INSERT_TYPESUB_FAILURE = 'INSERT_TYPESUB_FAILURE';

export const UPDATE_TYPESUB_REQUEST = 'UPDATE_TYPESUB_REQUEST';
export const UPDATE_TYPESUB_SUCCESS = 'UPDATE_TYPESUB_SUCCESS';
export const UPDATE_TYPESUB_FAILURE = 'UPDATE_TYPESUB_FAILURE';

export const DELETE_TYPESUB_REQUEST = 'DELETE_TYPESUB_REQUEST';
export const DELETE_TYPESUB_SUCCESS = 'DELETE_TYPESUB_SUCCESS';
export const DELETE_TYPESUB_FAILURE = 'DELETE_TYPESUB_FAILURE';
