import EditUserTemplate from "../../components/templates/user/edit";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadUserRequestAction} from "../../redux/user/reducer";
import LayoutTemplate from "../../components/templates/layout/left";

const EditUser = () => {
    const dispatch = useDispatch();
    const {seq} = useParams();

    const onLoadUser = useCallback(() => {
        dispatch(loadUserRequestAction({seq}));
    }, []);

    useEffect(() => {
        onLoadUser();
    }, []);

    return (
        <LayoutTemplate>
            <EditUserTemplate />
        </LayoutTemplate>
    );
};

export default EditUser;
