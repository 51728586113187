import axios from "axios";
import {
    DELETE_QUESTION_FAILURE,
    DELETE_QUESTION_REQUEST,
    DELETE_QUESTION_SUCCESS,
    INSERT_QUESTION_FAILURE,
    INSERT_QUESTION_REQUEST,
    INSERT_QUESTION_SUCCESS,
    LOAD_QUESTION_FAILURE, LOAD_QUESTION_REQUEST,
    LOAD_QUESTION_SUCCESS,
    LOAD_QUESTIONS_FAILURE,
    LOAD_QUESTIONS_REQUEST,
    LOAD_QUESTIONS_SUCCESS,
    UPDATE_QUESTION_FAILURE, UPDATE_QUESTION_REQUEST, UPDATE_QUESTION_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function loadQuestionsAPI() {
    return axios.get('/admin/api/v1/question')
}

function loadQuestionAPI(data) {
    return axios.get(`/admin/api/v1/question/${data.seq}`)
}

function insertQuestionAPI(data) {
    return axios.post('/admin/api/v1/question', data)
}

function updateQuestionAPI(data) {
    return axios.put('/admin/api/v1/question', data);
}

function deleteQuestionAPI(data) {
    return axios.delete('/admin/api/v1/question', {data})
}

function* loadQuestions(action) {
    try {
        const result = yield call(loadQuestionsAPI, action.data);
        yield put({
            type: LOAD_QUESTIONS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_QUESTIONS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadQuestion(action) {
    try {
        const result = yield call(loadQuestionAPI, action.data);
        yield put({
            type: LOAD_QUESTION_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertQuestion(action) {
    try {
        const result = yield call(insertQuestionAPI, action.data);
        yield put({
            type: INSERT_QUESTION_SUCCESS,
            data: result.data,
        });
        history.push('/question');
    } catch (err) {
        yield put({
            type: INSERT_QUESTION_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateQuestion(action) {
    try {
        const result = yield call(updateQuestionAPI, action.data);
        yield put({
            type: UPDATE_QUESTION_SUCCESS,
            data: result.data,
        });
        history.push('/question');
    } catch (err) {
        yield put({
            type: UPDATE_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteQuestion(action) {
    try {
        const result = yield call(deleteQuestionAPI, action.data);
        yield put({
            type: DELETE_QUESTION_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_QUESTION_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadQuestions() {
    yield takeLatest(LOAD_QUESTIONS_REQUEST, loadQuestions);
}

function* watchLoadQuestion() {
    yield takeLatest(LOAD_QUESTION_REQUEST, loadQuestion);
}

function* watchInsertQuestion() {
    yield takeLatest(INSERT_QUESTION_REQUEST, insertQuestion);
}

function* watchUpdateQuestion() {
    yield takeLatest(UPDATE_QUESTION_REQUEST, updateQuestion);
}

function* watchDeleteQuestion() {
    yield takeLatest(DELETE_QUESTION_REQUEST, deleteQuestion);
}

export default function* saga() {
    yield all([
        fork(watchLoadQuestions),
        fork(watchLoadQuestion),
        fork(watchInsertQuestion),
        fork(watchUpdateQuestion),
        fork(watchDeleteQuestion),
    ])
}

