import {all, fork} from 'redux-saga/effects';
import user from "./redux/user/saga";
import question from "./redux/question/saga";
import word from "./redux/word/saga";
import type from "./redux/type/saga";
import typeSub from "./redux/typeSub/saga";
import board from "./redux/board/saga";

export default function* rootSaga() {
    yield all([
        fork(user),
        fork(question),
        fork(word),
        fork(type),
        fork(typeSub),
        fork(board),
    ])
}
