import styled from "styled-components";
import {forwardRef} from "react";

const Checkbox = forwardRef(({...props}, ref) => {
    return (
        <InputWrap type={'checkbox'} {...props} ref={ref}/>
    )
});

const InputWrap = styled.input`
  cursor: pointer;

  width: ${({theme}) => {
    switch (theme) {
      case 'radio':
        return `48px`;
      case 'checkbox':
        return `20px`;
    }
  }};

  height: ${({theme}) => {
    switch (theme) {
      case 'radio':
        return `24px`;
      case 'checkbox':
        return `20px`;
    }
  }};

  background-image: ${({theme}) => {
    switch (theme) {
      case 'radio':
        return `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='24' viewBox='0 0 48 24' fill='none'%3e%3crect width='48' height='24' rx='12' fill='%23D1D1D6'/%3e%3ccircle cx='12' cy='12' r='10' fill='white'/%3e%3c/svg%3e")`;
      case 'checkbox':
        return `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3crect x='0.5' y='0.5' width='19' height='19' rx='3.5' stroke='%23D1D1D6'/%3e%3c/svg%3e")`;
    }
  }};

  &:checked {
    background-image: ${({theme}) => {
      switch (theme) {
        case 'radio':
          return `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='48' height='24' viewBox='0 0 48 24' fill='none'%3e%3crect width='48' height='24' rx='12' fill='%2376EE59'/%3e%3ccircle cx='36' cy='12' r='10' fill='white'/%3e%3c/svg%3e")`;
        case 'checkbox':
          return `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 20 20' fill='none'%3e%3crect width='20' height='20' rx='4' fill='%234169E1FF'/%3e%3cpath d='M14.6666 6.79166L8.24992 13.2083L5.33325 10.2917' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e")`;
      }
    }};
  }
`

export default Checkbox;
