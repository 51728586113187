import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect} from "react";
import {insertWordRequestAction} from "../../../redux/word/reducer";
import LayoutRightTemplate from "../layout/right";

const AddWordTemplate = () => {
    const dispatch = useDispatch();
    const typeSubs = useSelector(((state) => state.typeSub.typeSubs), shallowEqual);
    const [type, onChangeType, setType] = useInput(0);
    const [word, onChangeWord, setWord] = useInput('');
    const [meaning, onChangeMeaning, setMeaning] = useInput('');
    const [showYn, onChangeShowYn, setShowYn] = useInput(0);

    const onSubmit = useCallback(() => {
        if (!word || !meaning) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(insertWordRequestAction({type, word, meaning, showYn}));
    }, [type, word, meaning, showYn]);

    useEffect(() => {
        if (typeSubs.length > 0) setType(typeSubs[0].seq);
    }, [typeSubs]);

    return (
        <LayoutRightTemplate title={'단어 등록'} clickTitle={'등록'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'} margin={'20px 0 0 0'}> 타입 </Label>
                <Select options={typeSubs.map(i => {
                    return {
                        value: i.seq,
                        text: i.name
                    }
                })} value={type} onChange={(e) => setType(e.target.value)} margin={'10px 0 0 0'} />

                <Label fontWeight={'bold'}> 단어 </Label>
                <Input type={"text"} theme={'normal'} value={word} onChange={onChangeWord}/>

                <Label fontWeight={'bold'}> 뜻 </Label>
                <Input type={"text"} theme={'normal'} value={meaning} onChange={onChangeMeaning}/>

                <Label fontWeight={'bold'}> 공개 여부 </Label>
                <Select options={[{value: '0', text: '비공개'}, {value: '1', text: '공개'}]} value={showYn}
                        onChange={onChangeShowYn}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  & label, button {
    margin-top: 20px;
  }

  & input, select {
    margin-top: 10px;
  }
`

export default AddWordTemplate;
