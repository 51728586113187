import {
    DELETE_TYPE_FAILURE,
    DELETE_TYPE_REQUEST,
    DELETE_TYPE_SUCCESS,
    INSERT_TYPE_FAILURE,
    INSERT_TYPE_REQUEST,
    INSERT_TYPE_SUCCESS, LOAD_TYPE_FAILURE,
    LOAD_TYPE_REQUEST, LOAD_TYPE_SUCCESS,
    LOAD_TYPES_FAILURE,
    LOAD_TYPES_REQUEST,
    LOAD_TYPES_SUCCESS,
    UPDATE_TYPE_FAILURE, UPDATE_TYPE_REQUEST, UPDATE_TYPE_SUCCESS
} from "./constants";
import produce from "immer";

const initialState = {
    type: {},
    types: [],
    loadTypesLoading: false,
    loadTypesDone: false,
    loadTypesError: false,
    loadTypeLoading: false,
    loadTypeDone: false,
    loadTypeError: false,
    insertTypeLoading: false,
    insertTypeDone: false,
    insertTypeError: false,
    deleteTypeLoading: false,
    deleteTypeDone: false,
    deleteTypeError: false,
}

export const loadTypesRequestAction = (data) => {
    return {
        type: LOAD_TYPES_REQUEST,
        data,
    }
}

export const loadTypeRequestAction = (data) => {
    return {
        type: LOAD_TYPE_REQUEST,
        data,
    }
}

export const updateTypeRequestAction = (data) => {
    return {
        type: UPDATE_TYPE_REQUEST,
        data,
    }
}

export const insertTypeRequestAction = (data) => {
    return {
        type: INSERT_TYPE_REQUEST,
        data
    }
}

export const deleteTypeRequestAction = (data) => {
    return {
        type: DELETE_TYPE_REQUEST,
        data
    }
}

const reducer = (state = initialState, action) => produce(state, (draft) => {
    switch (action.type) {
        case LOAD_TYPE_REQUEST:
            draft.loadTypeLoading = true;
            draft.loadTypeDone = false;
            draft.loadTypeError = null;
            break;
        case LOAD_TYPE_SUCCESS:
            draft.loadTypeLoading = false;
            draft.loadTypeDone = true;
            draft.type = action.data.data;
            break;
        case LOAD_TYPE_FAILURE:
            draft.loadTypeLoading = false;
            draft.loadTypeDone = false;
            draft.loadTypeError = action.data;
            break;
        case LOAD_TYPES_REQUEST:
            draft.loadTypesLoading = true;
            draft.loadTypesDone = false;
            draft.loadTypesError = null;
            break;
        case LOAD_TYPES_SUCCESS:
            draft.loadTypesLoading = false;
            draft.loadTypesDone = true;
            draft.types = action.data.data;
            break;
        case LOAD_TYPES_FAILURE:
            draft.loadTypesLoading = false;
            draft.loadTypesDone = false;
            draft.loadTypesError = action.data;
            break;
        case INSERT_TYPE_REQUEST:
            draft.insertTypeLoading = true;
            draft.insertTypeDone = false;
            draft.insertTypeError = null;
            break;
        case INSERT_TYPE_SUCCESS:
            draft.insertTypeLoading = false;
            draft.insertTypeDone = true;
            break;
        case INSERT_TYPE_FAILURE:
            draft.insertTypeLoading = false;
            draft.insertTypeDone = false;
            draft.insertTypeError = action.data;
            break;
        case UPDATE_TYPE_REQUEST:
            draft.updateTypeLoading = true;
            draft.updateTypeDone = false;
            draft.updateTypeError = null;
            break;
        case UPDATE_TYPE_SUCCESS:
            draft.updateTypeLoading = false;
            draft.updateTypeDone = true;
            break;
        case UPDATE_TYPE_FAILURE:
            draft.updateTypeLoading = false;
            draft.updateTypeDone = false;
            draft.updateTypeError = action.data;
            break;
        case DELETE_TYPE_REQUEST:
            draft.deleteTypeLoading = true;
            draft.deleteTypeDone = false;
            draft.deleteTypeError = null;
            break;
        case DELETE_TYPE_SUCCESS:
            draft.deleteTypeLoading = false;
            draft.deleteTypeDone = true;
            break;
        case DELETE_TYPE_FAILURE:
            draft.deleteTypeLoading = false;
            draft.deleteTypeDone = false;
            draft.deleteTypeError = action.data;
            break;
    }
});

export default reducer;

