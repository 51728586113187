import axios from "axios";
import {
    DELETE_TYPESUB_FAILURE,
    DELETE_TYPESUB_REQUEST,
    DELETE_TYPESUB_SUCCESS,
    INSERT_TYPESUB_FAILURE,
    INSERT_TYPESUB_REQUEST,
    INSERT_TYPESUB_SUCCESS,
    LOAD_TYPESUB_FAILURE, LOAD_TYPESUB_REQUEST,
    LOAD_TYPESUB_SUCCESS, LOAD_TYPESUBS_BY_TYPE_FAILURE, LOAD_TYPESUBS_BY_TYPE_REQUEST, LOAD_TYPESUBS_BY_TYPE_SUCCESS,
    LOAD_TYPESUBS_FAILURE,
    LOAD_TYPESUBS_REQUEST,
    LOAD_TYPESUBS_SUCCESS,
    UPDATE_TYPESUB_FAILURE, UPDATE_TYPESUB_REQUEST, UPDATE_TYPESUB_SUCCESS
} from "./constants";
import {all, fork, takeLatest, call, put} from 'redux-saga/effects';
import history from "../../utils/history";

function loadTypeSubsAPI(data) {
    return axios.get(`/admin/api/v1/typeSub`)
}

function loadTypeSubsByTypeAPI(data) {
    return axios.get(`/admin/api/v1/typeSub/${data.typeSeq}`)
}

function loadTypeSubAPI(data) {
    return axios.get(`/admin/api/v1/typeSub/detail/${data.seq}`)
}

function insertTypeSubAPI(data) {
    return axios.post('/admin/api/v1/typeSub', data)
}

function updateTypeSubAPI(data) {
    return axios.put('/admin/api/v1/typeSub', data);
}

function deleteTypeSubAPI(data) {
    return axios.delete('/admin/api/v1/typeSub', {data})
}

function* loadTypeSubs(action) {
    try {
        const result = yield call(loadTypeSubsAPI, action.data);
        yield put({
            type: LOAD_TYPESUBS_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TYPESUBS_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadTypeSubsByType(action) {
    try {
        const result = yield call(loadTypeSubsByTypeAPI, action.data);
        yield put({
            type: LOAD_TYPESUBS_BY_TYPE_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TYPESUBS_BY_TYPE_FAILURE,
            data: err.response.data,
        });
    }
}

function* loadTypeSub(action) {
    try {
        const result = yield call(loadTypeSubAPI, action.data);
        yield put({
            type: LOAD_TYPESUB_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: LOAD_TYPESUB_FAILURE,
            data: err.response.data,
        });
    }
}

function* insertTypeSub(action) {
    try {
        const result = yield call(insertTypeSubAPI, action.data);
        yield put({
            type: INSERT_TYPESUB_SUCCESS,
            data: result.data,
        });
        history.push(`/typeSub?typeSeq=${action.data.typeSeq}`);
    } catch (err) {
        yield put({
            type: INSERT_TYPESUB_FAILURE,
            data: err.response.data,
        });
        alert(err.response.data.data.message);
    }
}

function* updateTypeSub(action) {
    try {
        const result = yield call(updateTypeSubAPI, action.data);
        yield put({
            type: UPDATE_TYPESUB_SUCCESS,
            data: result.data,
        });
        history.push(`/typeSub?typeSeq=${action.data.typeSeq}`);
    } catch (err) {
        yield put({
            type: UPDATE_TYPESUB_FAILURE,
            data: err.response.data,
        });
    }
}

function* deleteTypeSub(action) {
    try {
        const result = yield call(deleteTypeSubAPI, action.data);
        yield put({
            type: DELETE_TYPESUB_SUCCESS,
            data: result.data,
        });
    } catch (err) {
        yield put({
            type: DELETE_TYPESUB_FAILURE,
            data: err.response.data,
        });
    }
}

function* watchLoadTypeSubs() {
    yield takeLatest(LOAD_TYPESUBS_REQUEST, loadTypeSubs);
}

function* watchLoadTypeSubsByType() {
    yield takeLatest(LOAD_TYPESUBS_BY_TYPE_REQUEST, loadTypeSubsByType);
}

function* watchLoadTypeSub() {
    yield takeLatest(LOAD_TYPESUB_REQUEST, loadTypeSub);
}

function* watchInsertTypeSub() {
    yield takeLatest(INSERT_TYPESUB_REQUEST, insertTypeSub);
}

function* watchUpdateTypeSub() {
    yield takeLatest(UPDATE_TYPESUB_REQUEST, updateTypeSub);
}

function* watchDeleteTypeSub() {
    yield takeLatest(DELETE_TYPESUB_REQUEST, deleteTypeSub);
}

export default function* saga() {
    yield all([
        fork(watchLoadTypeSubs),
        fork(watchLoadTypeSubsByType),
        fork(watchLoadTypeSub),
        fork(watchInsertTypeSub),
        fork(watchUpdateTypeSub),
        fork(watchDeleteTypeSub),
    ])
}

