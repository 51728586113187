import Label from "../../atoms/label";
import styled from "styled-components";
import Input from "../../atoms/input";

const File = ({children, ...props}) => {
    return (
        <FileLabelWrap {...props}>
            {children}
            <FileInputWrap type={'file'} id={props.htmlFor} onInput={props.onInput} onChange={props.onChange} />
        </FileLabelWrap>
    )
}

const FileLabelWrap = styled(Label)`
  width: 100%;
  font-weight: 600;
  cursor: pointer;
  display: ${({display}) => display };
  align-items: ${({alignItems}) => alignItems };
  justify-content : ${({justifyContent}) => justifyContent };
  max-width: ${({maxWidth}) => maxWidth };
  max-height: ${({maxHeight}) => maxHeight };
  margin: ${({margin}) => margin};
  float: ${({float}) => float};
  font-size: ${({fontSize}) => fontSize};
  height: ${({size}) => {
    switch (size) {
      case 'xs':
        return '35px';
      case 'sm':
        return '45px';
      case 'md':
        return '60px';
      case 'full':
        return '100%';
    }
  }};
  background-color: ${({color}) => {
    switch (color) {
      case 'orange':
        return 'rgb(244, 132, 98)';
      case 'yellow':
        return 'rgb(247, 204, 105)';
      case 'navy':
        return '#4169E1FF';
      case 'gray':
        return '#ddd';
      case 'white':
        return '#fff';
    }
  }};
  border-radius: ${({shape}) => {
    switch (shape) {
      default:
      case 'rectangle':
        return '6px;';
      case 'oval':
        return '23px;';
      case 'circle':
        return '100%;';
    }
  }};
  color: ${({color}) => {
    switch (color) {
      case 'orange':
      case 'yellow':
      case 'navy':
        return '#fff';
      case 'white':
        return 'rgb(244, 132, 98)';
    }
  }};
`

const FileInputWrap = styled(Input)`
    display: none;
`

export default File;