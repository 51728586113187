import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useState} from "react";
import {updateUserRequestAction} from "../../../redux/user/reducer";
import LayoutRightTemplate from "../layout/right";
import {Role} from "../../../constants/role";

const EditUserTemplate = () => {
    const dispatch = useDispatch();
    const user = useSelector(((state) => state.user.user), shallowEqual);
    const [seq, setSeq] = useState('');
    const [id, onChangeId, setId] = useInput('');
    const [email, onChangeEmail, setEmail] = useInput('');
    const [password, onChangePassword, setPassword] = useInput('');
    const [name, onChangeName, setName] = useInput('');
    const [penalty, onChangePenalty, setPenalty] = useInput('');
    const [role, onChangeRole, setRole] = useInput('Admin');
    const [useYn, onChangeUseYn, setUseYn] = useInput('');

    useEffect(() => {
        setSeq(user.seq);
        setId(user.id);
        setEmail(user.email);
        setPassword(user.password);
        setRole(user.role);
        setName(user.name);
        setPenalty(user.penalty);
        setUseYn(user.useYn);
    }, [user]);

    const onSubmit = useCallback(() => {
        if (!id || !email || !password || !name || penalty === '') {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(updateUserRequestAction({id, email, password, name, role, seq, useYn, penalty}));
    }, [id, email, password, name, role, seq, useYn, penalty]);

    return (
        <LayoutRightTemplate title={'유저 수정'} clickTitle={'수정'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'}> 아이디 </Label>
                <Input type={"text"} theme={'normal'} value={id} onChange={onChangeId}/>

                <Label fontWeight={'bold'}> 이메일 </Label>
                <Input type={"text"} theme={'normal'} value={email} onChange={onChangeEmail}/>

                <Label fontWeight={'bold'}> 비밀번호 </Label>
                <Input type={"password"} theme={'normal'} value={password} onChange={onChangePassword} disabled/>

                <Label fontWeight={'bold'}> 닉네임 </Label>
                <Input type={"text"} theme={'normal'} value={name} onChange={onChangeName}/>

                <Label fontWeight={'bold'}> 패널티 </Label>
                <Input type={"number"} theme={'normal'} value={penalty} onChange={onChangePenalty}/>

                <Label fontWeight={'bold'}> 권한 </Label>
                <Select options={Role.map((i) => ({value: i, text: i}))} value={role}
                        onChange={onChangeRole}/>

                <Label fontWeight={'bold'}> 탈퇴 여부 </Label>
                <Select options={[{value: '0', text: '탈퇴'}, {value: '1', text: '정상'}]} value={useYn}
                        onChange={onChangeUseYn}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;

  & label, button {
    margin-top: 20px;
  }

  & input, select {
    margin-top: 10px;
  }
`

export default EditUserTemplate;
