import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useMemo} from "react";
import {deleteTypeSubRequestAction} from "../../../redux/typeSub/reducer";
import {showAlertRequestAction} from "../../../redux/alert/reducer";
import Table from "../../atoms/table";
import history from "../../../utils/history";
import Tag from "../../atoms/tag";
import LayoutRightTemplate from "../layout/right";
import Action from "../../atoms/table/action";
import Checkbox from "../../atoms/checkbox";
import Button from "../../atoms/button";
import getQueryString from "../../../utils/getQueryString";

const TypeSubTemplate = () => {
    const {typeSeq} = getQueryString();
    const dispatch = useDispatch();
    const typeSubs = useSelector(((state) => state.typeSub.typeSubsByType), shallowEqual);

    const goAdd = useCallback(() => {
        history.push(`/typeSub/add?typeSeq=${typeSeq}`);
    }, []);

    const goEdit = useCallback((seq) => {
        history.push(`/typeSub/edit/${seq}?typeSeq=${typeSeq}`);
    }, []);

    const onDelete = useCallback((seq) => {
        dispatch(showAlertRequestAction({
            showAlert: true, title: '정말 삭제하시겠습니까?', onSubmit: () => {
                dispatch(deleteTypeSubRequestAction({seq}));
                window.location.reload();
            }
        }));
    }, []);

    const columns = useMemo(
        () => [
            {
                id: 'check',
                Header: ({getToggleAllPageRowsSelectedProps, toggleAllPageRowsSelected}) => {
                    return <Checkbox
                        theme={'checkbox'} {...getToggleAllPageRowsSelectedProps({onChange: (e) => toggleAllPageRowsSelected(e.target.checked)})} />
                },
                Cell: ({row}) => {
                    return <Checkbox
                        theme={'checkbox'} {...row.getToggleRowSelectedProps({onChange: () => row.toggleRowSelected(!row.isSelected)})} />
                },
                width: '3%'
            },
            {Header: '상태', accessor: 'showYn', width: '5%'},
            {Header: '정렬', accessor: 'sort', width: '5%'},
            {Header: '번호', accessor: 'seq', width: '5%'},
            {Header: '이름', accessor: 'name'},
            {Header: '등록일', accessor: 'insertDate'},
            {Header: '등록자', accessor: 'insertName'},
            {Header: '수정일', accessor: 'updateDate'},
            {Header: '수정자', accessor: 'updateName'},
            {Header: '', accessor: 'action', width: '5%'},
        ], []);

    const data = useMemo(() => typeSubs.length > 0 ? typeSubs.map((x) => {
        return {
            ...x,
            showYn: x.showYn === 0 ? <Tag theme={'red'}>비공개</Tag> : <Tag theme={'navy'}>공개</Tag>,
            action: Action(x.seq, goEdit, onDelete),
        };
    }) : [], [typeSubs]);

    const onCheckedDelete = useCallback((selectedFlatRows) => {
        if (selectedFlatRows.length === 0) return false;

        const result = [];
        for (let row of selectedFlatRows) {
            result.push({
                seq: row.original.seq,
            });
        }

        dispatch(showAlertRequestAction({
            showAlert: true,
            title: '정말 삭제하시겠습니까?',
            onSubmit: () => {
                dispatch(deleteTypeSubRequestAction({seq: result.map((i) => i.seq).join(',')}));
                window.location.reload();
            }
        }));
    }, []);

    return (
        <LayoutRightTemplate title={'하위 타입 관리'} clickTitle={'등록'} onClick={goAdd}>
            <Table columns={columns} data={data} mainSearch={true}
                   leftButtons={(selectedFlatRows) => (
                       <Button size={'xs'} color={'navy'} onClick={() => onCheckedDelete(selectedFlatRows)}>
                           <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                               <path d="M3 6h18"></path>
                               <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6"></path>
                               <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2"></path>
                               <line x1="10" y1="11" x2="10" y2="17"></line>
                               <line x1="14" y1="11" x2="14" y2="17"></line>
                           </svg>
                       </Button>
                   )}
            />
        </LayoutRightTemplate>
    );
};

export default TypeSubTemplate;
