import EditTypeTemplate from "../../components/templates/type/edit";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadTypeRequestAction} from "../../redux/type/reducer";
import LayoutTemplate from "../../components/templates/layout/left";

const EditType = () => {
    const dispatch = useDispatch();
    const {seq} = useParams();

    const onLoadType = useCallback(() => {
        dispatch(loadTypeRequestAction({seq}));
    }, []);

    useEffect(() => {
        onLoadType();
    }, []);

    return (
        <LayoutTemplate>
            <EditTypeTemplate />
        </LayoutTemplate>
    );
};

export default EditType;
