import TypeTemplate from "../../components/templates/type";
import {useCallback, useEffect} from "react";
import {loadTypesRequestAction} from "../../redux/type/reducer";
import {useDispatch} from "react-redux";
import LayoutTemplate from "../../components/templates/layout/left";

const Type = () => {
    const dispatch = useDispatch();
    const onLoadType = useCallback(() => {
        dispatch(loadTypesRequestAction());
    }, []);

    useEffect(() => {
        onLoadType();
    }, []);

    return (
        <LayoutTemplate>
            <TypeTemplate />
        </LayoutTemplate>
    );
};

export default Type;
