import {unstable_HistoryRouter as HistoryRouter, Routes, Route} from "react-router-dom";
import Login from "../login";
import history from "../../utils/history";
import {useDispatch} from "react-redux";
import "../../assets/css/common.css";
import {useCallback, useEffect} from "react";
import {loginCheckRequestAction} from "../../redux/user/reducer";
import User from "../user";
import AddUser from "../user/add";
import EditUser from "../user/edit";
import EditWord from "../word/edit";
import AddWord from "../word/add";
import Word from "../word";
import EditQuestion from "../question/edit";
import AddQuestion from "../question/add";
import Question from "../question";
import Board from "../board";
import AddBoard from "../board/add";
import EditBoard from "../board/edit";
import Type from "../type";
import AddType from "../type/add";
import EditType from "../type/edit";
import TypeSub from "../typeSub";
import AddTypeSub from "../typeSub/add";
import EditTypeSub from "../typeSub/edit";

const App = () => {
    const dispatch = useDispatch();
    const loginCheck = useCallback(() => {
        dispatch(loginCheckRequestAction());
    }, []);

    history.listen(() => {
        loginCheck();
    });

    useEffect(() => {
        loginCheck();
    }, []);

    return (
        <HistoryRouter history={history}>
            <Routes>
                <Route path="/login" element={<Login />}/>

                <Route path="/user" element={<User />}/>
                <Route path="/user/add" element={<AddUser />}/>
                <Route path="/user/edit/:seq" element={<EditUser />}/>

                <Route path="/question" element={<Question />}/>
                <Route path="/question/add" element={<AddQuestion />}/>
                <Route path="/question/edit/:seq" element={<EditQuestion />}/>

                <Route path="/type" element={<Type />}/>
                <Route path="/type/add" element={<AddType />}/>
                <Route path="/type/edit/:seq" element={<EditType />}/>

                <Route path="/typeSub" element={<TypeSub />}/>
                <Route path="/typeSub/add" element={<AddTypeSub />}/>
                <Route path="/typeSub/edit/:seq" element={<EditTypeSub />}/>

                <Route path="/word" element={<Word />}/>
                <Route path="/word/add" element={<AddWord />}/>
                <Route path="/word/edit/:seq" element={<EditWord />}/>

                <Route path="/board" element={<Board />}/>
                <Route path="/board/add" element={<AddBoard />}/>
                <Route path="/board/edit/:seq" element={<EditBoard />}/>
            </Routes>
        </HistoryRouter>
    );
}

export default App;
