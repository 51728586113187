import EditQuestionTemplate from "../../components/templates/question/edit";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadQuestionRequestAction} from "../../redux/question/reducer";
import LayoutTemplate from "../../components/templates/layout/left";

const EditQuestion = () => {
    const dispatch = useDispatch();
    const {seq} = useParams();

    const onLoadQuestion = useCallback(() => {
        dispatch(loadQuestionRequestAction({seq}));
    }, []);

    useEffect(() => {
        onLoadQuestion();
    }, []);

    return (
        <LayoutTemplate>
            <EditQuestionTemplate />
        </LayoutTemplate>
    );
};

export default EditQuestion;
