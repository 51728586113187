import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {updateBoardRequestAction} from "../../../redux/board/reducer";
import LayoutRightTemplate from "../layout/right";
import JoditEditor from "jodit-react";

const EditBoardTemplate = () => {
    const dispatch = useDispatch();
    const board = useSelector(((state) => state.board.board), shallowEqual);
    const [seq, setSeq] = useState('');
    const [title, onChangeTitle, setTitle] = useInput('');
    const [content, onChangeContent, setContent] = useInput('');
    const [answer, onChangeAnswer, setAnswer] = useInput('');
    const [categorySeq, onChangeCategorySeq, setCategorySeq] = useInput(1);
    const [showYn, onChangeShowYn, setShowYn] = useInput(0);
    const [showRole, onChangeShowRole, setShowRole] = useInput(0);

    const editor = useRef(null);
    const config = useMemo(() => ({
        readonly: false,
        width: '100%',
        height: '100%',
        buttons: [
            'bold',
            'italic',
            'underline',
            'fontsize',
            '|',
            'left',
            'center',
            'right',
            'justify',
            '|',
            'undo',
            'redo',
        ],
        removeButtons: ['brush', 'link', 'fullsize', 'image', 'table', 'eraser', 'paragraph', 'copyformat'],
        allowResizeX: false,
        allowResizeY: false,
        cleanHTML: {
            fillEmptyParagraph: false,
            removeEmptyElements: false,
        }
    }), []);

    useEffect(() => {
        setSeq(board.seq);
        setTitle(board.title);
        setContent(board.content);
        setAnswer(board.answer);
        setCategorySeq(board.categorySeq);
        setShowYn(board.showYn);
        setShowRole(board.showRole);
    }, [board]);

    const onSubmit = useCallback(() => {
        if (!title || !content) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(updateBoardRequestAction({title, content, answer, seq, showYn, categorySeq, showRole}));
    }, [title, content, answer, seq, showYn, categorySeq, showRole]);

    return (
        <LayoutRightTemplate title={'게시글 수정'} clickTitle={'수정'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'}> 카테고리 </Label>
                <Select options={[{value: '1', text: 'Q&A'}, {value: '2', text: '학습하기'}, {value: '3', text: '안내게시판'}]} value={categorySeq}
                        onChange={onChangeCategorySeq}/>

                <Label fontWeight={'bold'}> 제목 </Label>
                <Input type={"text"} theme={'normal'} value={title} onChange={onChangeTitle}/>

                <Label fontWeight={'bold'}> 내용 </Label>
                <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    tabIndex={1}
                    onBlur={newContent => setContent(newContent)}
                />

                <Label fontWeight={'bold'}> 답변 </Label>
                <JoditEditor
                    ref={editor}
                    value={answer}
                    config={config}
                    tabIndex={2}
                    onBlur={newContent => setAnswer(newContent)}
                />

                <Label fontWeight={'bold'}> 공개 여부 </Label>
                <Select options={[{value: '0', text: '비공개'}, {value: '1', text: '공개'}]} value={showYn}
                        onChange={onChangeShowYn}/>

                <Label fontWeight={'bold'}> 공개 권한 </Label>
                <Select options={[{value: '0', text: '비회원'}, {value: '1', text: '회원'}, {value: '2', text: '정회원'}]} value={showRole}
                        onChange={onChangeShowRole}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  & .jodit-react-container,
  & label {
    margin-top: 20px;
  }

  & input, select {
    margin-top: 10px;
  }
`

export default EditBoardTemplate;
