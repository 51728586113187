export const LOAD_TYPES_REQUEST = 'LOAD_TYPES_REQUEST';
export const LOAD_TYPES_SUCCESS = 'LOAD_TYPES_SUCCESS';
export const LOAD_TYPES_FAILURE = 'LOAD_TYPES_FAILURE';

export const LOAD_TYPE_REQUEST = 'LOAD_TYPE_REQUEST';
export const LOAD_TYPE_SUCCESS = 'LOAD_TYPE_SUCCESS';
export const LOAD_TYPE_FAILURE = 'LOAD_TYPE_FAILURE';

export const INSERT_TYPE_REQUEST = 'INSERT_TYPE_REQUEST';
export const INSERT_TYPE_SUCCESS = 'INSERT_TYPE_SUCCESS';
export const INSERT_TYPE_FAILURE = 'INSERT_TYPE_FAILURE';

export const UPDATE_TYPE_REQUEST = 'UPDATE_TYPE_REQUEST';
export const UPDATE_TYPE_SUCCESS = 'UPDATE_TYPE_SUCCESS';
export const UPDATE_TYPE_FAILURE = 'UPDATE_TYPE_FAILURE';

export const DELETE_TYPE_REQUEST = 'DELETE_TYPE_REQUEST';
export const DELETE_TYPE_SUCCESS = 'DELETE_TYPE_SUCCESS';
export const DELETE_TYPE_FAILURE = 'DELETE_TYPE_FAILURE';
