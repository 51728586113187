import EditBoardTemplate from "../../components/templates/board/edit";
import {useParams} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useCallback, useEffect} from "react";
import {loadBoardRequestAction} from "../../redux/board/reducer";
import LayoutTemplate from "../../components/templates/layout/left";

const EditBoard = () => {
    const dispatch = useDispatch();
    const {seq} = useParams();

    const onLoadBoard = useCallback(() => {
        dispatch(loadBoardRequestAction({seq}));
    }, []);

    useEffect(() => {
        onLoadBoard();
    }, []);

    return (
        <LayoutTemplate>
            <EditBoardTemplate />
        </LayoutTemplate>
    );
};

export default EditBoard;
