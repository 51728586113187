import Label from "../../atoms/label";
import Input from "../../atoms/input";
import styled from "styled-components";
import Select from "../../atoms/select";
import useInput from "../../../hooks/useInput";
import {useDispatch} from "react-redux";
import {useCallback} from "react";
import {insertUserRequestAction} from "../../../redux/user/reducer";
import LayoutRightTemplate from "../layout/right";
import {Role} from "../../../constants/role";

const AddUserTemplate = () => {
    const dispatch = useDispatch();
    const [id, onChangeId, setId] = useInput('');
    const [email, onChangeEmail, setEmail] = useInput('');
    const [password, onChangePassword, setPassword] = useInput('');
    const [name, onChangeName, setName] = useInput('');
    const [role, onChangeRole, setRole] = useInput('Admin');

    const onSubmit = useCallback(() => {
        if (!id || !email || !password || !name) {
            alert('필수 값을 입력해주세요.');
            return false;
        }

        dispatch(insertUserRequestAction({id, email, password, name, role}));
    }, [id, email, password, name, role]);

    return (
        <LayoutRightTemplate title={'유저 등록'} clickTitle={'등록'} onClick={onSubmit}>
            <FormWrap>
                <Label fontWeight={'bold'}> 아이디 </Label>
                <Input type={"text"} theme={'normal'} value={id} onChange={onChangeId}/>

                <Label fontWeight={'bold'}> 이메일 </Label>
                <Input type={"text"} theme={'normal'} value={email} onChange={onChangeEmail}/>

                <Label fontWeight={'bold'}> 비밀번호 </Label>
                <Input type={"password"} theme={'normal'} value={password} onChange={onChangePassword}/>

                <Label fontWeight={'bold'}> 닉네임 </Label>
                <Input type={"text"} theme={'normal'} value={name} onChange={onChangeName}/>

                <Label fontWeight={'bold'}> 권한 </Label>
                <Select options={Role.map((i) => ({value: i, text: i}))} value={role}
                        onChange={onChangeRole}/>
            </FormWrap>
        </LayoutRightTemplate>
    )
}

const FormWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  
  & label, button {
    margin-top: 20px;
  }

  & input, select {
    margin-top: 10px;
  }
`

export default AddUserTemplate;
